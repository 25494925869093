/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl, useIntl } from 'react-intl';
import TradeViewChart from 'components/Chart/tradeViewChart';
import Page from 'components/Page/Page';
import { useUser } from '../../../context/UserContext';
import './styles/detail-chart.scss';
import { currencyFormatUSD } from '../../../ultils/CurrencyFormat';
//icon
import ic_arrowDown from '../../../assets/bo-icons/ic_arrow_down.svg';
import ic_close from '../../../assets/bo-icons/ic_close.svg';
import ic_stop from '../../../assets/bo-icons/ic_stop_watch.svg';
import ic_question from '../../../assets/bo-icons/ic_question.svg';
import ic_clockNew from '../../../assets/bo-icons/ic_clock.svg';
import ic_volume from '../../../assets/bo-icons/ic_volume.svg';
import ic_volume_mute from '../../../assets/bo-icons/ic_mute.svg';
import ic_diagram from '../../../assets/bo-icons/ic_diagram_up.svg';
import ic_money from '../../../assets/bo-icons/ic_money_wallet.svg';
import ic_up from '../../../assets/bo-icons/ic_course_up.svg';
import ic_down from '../../../assets/bo-icons/ic_course_down.svg';
import ic_eye from '../../../assets/bo-icons/ic-eye.svg';
// import video_betsuccess from '/public/game_bo/videos/video_betsuccess.mp4';
// import video_betwin from '/public/game_bo/videos/video_betwin.mp4';
// import video_betlose from '/public/game_bo/videos/video_betlose.mp4';
// import pdfFile from '/public/game_bo/guideline.pdf';
import ModalListCoinChart from '../../../components/Modal/components/ModalListCoinChart/ModalListCoinChart';
import DetailCoinService from 'services/detailCoin';
import swal from 'sweetalert';
import Loader from 'components/Loader';
import AppUsers from '../../../services/apppUsers';
import { getGameRecordUserGetList, handleUpdateDetail } from '../../../actions';
import { BINANCE_PARAMS, COIN_LIST } from '../../../constants/listCoin';
import { formatToPrice } from 'helper/common';
import ModalShowResult from 'components/Modal/components/ModalShowResult/ModalShowResult';
import GameRecordService from 'services/gameRecordService';
import ModalShowBetLose from 'components/Modal/components/ModalShowBetLose/ModalShowBetLose';
import ModalShowBetWin from 'components/Modal/components/ModalShowBetWin/ModalShowBetWin';
import { TYPE_CHART_PLAY } from 'constants/socket';
// import { SOCKET_WSS, SOCKET_WS } from 'constants/url';

const DetailChart = props => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);
  const [pair, setPair] = useState(searchParams?.get('type') ? searchParams?.get('type') : 'BTCUSD');
  const intervalChart = searchParams?.get('interval') ? searchParams?.get('interval') : '1m';
  const tokenParams = searchParams.get('token');
  const [isOpenModalListCoin, setIsOpenModalListCoin] = useState(false);
  const { user } = useUser();
  const [coinData, setCoinData] = useState(COIN_LIST);
  const [newCoinData, setNewCoinData] = useState(COIN_LIST);
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountDown] = useState(0);
  const [money, setMoney] = useState('');
  const [moneyWinLose, setMoneyWinLose] = useState(0);
  const [priceNewCoin, setPriceNewCoin] = useState(0);
  const [showModalResult, setShowModalResult] = useState(false);
  const [showModalBetWin, setShowModalBetWin] = useState(false);
  const [showModalBetLose, setShowModalBetLose] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showTicket, setShowTicket] = useState('');
  const data = useSelector(state => (state.member ? state.member : null));
  const [resultGameBO, setResultGameBO] = useState(undefined);
  const [newResult, setNewResult] = useState();
  const [completed, setCompleted] = useState(0);
  const [showMusic, setShowMusic] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showLineMA, setShowLineMA] = useState(false);
  const { handleChooseMusic } = props;
  const [periodCode, setPeriodCode] = useState(0);
  const ref = useRef();
  const socketUrl = 'wss://stream.binance.com:9443/ws';
  const typeTimeChart = searchParams?.get('typeTimeChart')
  ? TYPE_CHART_PLAY.find((item) => item.name + '' === searchParams?.get('typeTimeChart'))
  : TYPE_CHART_PLAY[0]
  const headerRight = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          marginRight: '6px',
        }}
      >
        {currencyFormatUSD(user?.wallets?.find(wallet => wallet?.walletType === 'PointWallet').balance || 0)} USDT
      </span>{' '}
    </div>
  );
  function handleChangeTypeCoin(pair) {
    setPair(pair);
    setIsOpenModalListCoin(false);
    checkParams({ type: pair });
  }
  const checkParams = filterParams => {
    const params = {};
    if (filterParams?.type) {
      params.type = filterParams.type;
    }
    if (searchParams.get('token')) {
      params.token = searchParams.get('token');
    }
    const urlSearchParams = new URLSearchParams(params);
    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
  };

  useEffect(() => {
    getListResult();
  }, [pair]);

  const getDataWebSocket = () => {
    const ws = new WebSocket(socketUrl);
    let isCheck = false;
    ws.onopen = () => {
      const params = {
        method: 'SUBSCRIBE',
        params: BINANCE_PARAMS,
        id: 1,
      };
      ws.send(JSON.stringify(params));
    };
    ws.onmessage = evt => {
      const data = evt?.data ? JSON.parse(evt?.data) : {};
      if (data?.s) {
        for (let coin of coinData) {
          if (coin?.symbol === data?.s) {
            coin.price = Number(data?.c);
            coin.percent = data?.P;
          }
        }
        setCoinData(coinData);
        if (!isCheck) {
          isCheck = true;
          setNewCoinData([...coinData]);
        }
      }
    };
    setIsLoading(false);
    ws.onclose = () => {};
    return () => {
      ws.close();
    };
  };
  useEffect(() => {
    tokenParams && handleReload();
    getDataWebSocket();
  }, []);
  useEffect(() => {
    if (newResult) {
      const index = resultGameBO?.findIndex(item => !item?.gameRecordValue);
      if (index === 124) {
        getListResult();
      } else {
        resultGameBO[index] = { gameRecordValue: '1234.342' };
        setResultGameBO([...resultGameBO]);
      }
    }
  }, [newResult]);
  const convertPercent = number => {
    if (number !== undefined && number !== null) {
      return parseFloat(Math.round(number * 100) / 100).toFixed(2);
    } else return 0;
  };

  function handleApiPlaceRecord(params) {
    setIsLoading(true);
    DetailCoinService.gamePlaceRecord(params).then(result => {
      const { isSuccess } = result;
      setIsLoading(false);
      if (isSuccess) {
        handleReload();
        setShowModalResult(true);
        setMoney('');
        // setIsOpenModalReserveTicket(false);
        // setIsOpenModalOrderPlacedSuccess(true);
      } else {
        swal(f({ id: 'error' }), {
          icon: 'warning',
        });
      }
    });
  }

  const handleClick = e => {
    if (!ref.current?.contains(e.target)) setIsOpenModalListCoin(false);
  };
  useEffect(() => {
   
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  function handleReload() {
    getDetailUserById();
  }
  function getDetailUserById() {
    if (data?.appUserId)
      AppUsers.getDetailUser()
        .then(result => {
          const { isSuccess, data } = result;
          if (isSuccess) {
            dispatch(handleUpdateDetail(data));
          }
          setIsLoading(false);
        })
        .catch(() => {});
  }
  const handleAddMoney = value => {
    let count = parseInt(money?.replaceAll(',', '') || 0);
    setMoney(formatToPrice(value + count));
  };
  const handleSelectCommand = value => {
    if (!money) {
      swal('Bạn chưa nhập số tiền', {
        icon: 'warning',
      });
      return;
    }
    if (parseFloat(money.replaceAll(',', '')) < 1000) {
      swal('Số tiền đặt tối thiểu 1,000', {
        icon: 'warning',
      });
      return;
    }
    if (
      parseInt(money.replaceAll(',', '')) > user?.wallets?.find(wallet => wallet?.walletType === 'PointWallet').balance
    ) {
      swal(f({ id: 'NOT_ENOUGH_BALANCE' }), {
        icon: 'warning',
      });
      return;
    }
    const params = {
      betRecordAmountIn: parseInt(money.replaceAll(',', '')),
      betRecordType: 'BINARYOPTION_UPDOWN',
      betRecordValue: value ? 'TANG' : 'GIAM',
      betRecordSection: `${periodCode}-008-${newCoinData?.find(item => item?.symbol === pair)?.type_coin || 'BTC-USD'}`,
    };
    handleApiPlaceRecord(params);
  };

  const processIncrement = () => {
    if (completed < 1) {
      setCompleted(completed + 0.2);
    }
  };
  useEffect(() => {
    const interval = setInterval(processIncrement, 700);
    return () => {
      clearInterval(interval);
    };
  }, [completed]);

  const getListResult = () => {
    const params = {
      filter: {
        gameRecordType: 'BINARYOPTION_UPDOWN',
        gameRecordUnit: COIN_LIST?.find((item) => item?.symbol === pair)?.type_place_record || 'BTC-USDT'
      },
      skip: 0,
      limit: 80,
    };
    GameRecordService.getList(params).then(result => {
      const { isSuccess, data } = result;
      if (isSuccess) {
        const fulldata = data?.data?.reverse()
        setResultGameBO([...fulldata, ...new Array(20)]);
        dispatch(getGameRecordUserGetList(fulldata))
      }
    });
  };
  // console.log(resultGameBO?.findIndex(item => !item?.gameRecordValue));

  function countResultGameBo(array = [], value) {
    if (array?.length > 0) {
      return array?.filter(item => item?.gameRecordResult === value)?.length;
    }
    return 0;
  }
  return (
    <Page headerRight={headerRight}>
      {isLoading ? <Loader /> : null}
      <div id={'Chart'}>
        <div className="chart">
          <div className="d-flex" style={{ paddingTop: '30px', paddingBottom: '20px' }}>
            <div className="chartBtn" onClick={() => setShowPDF(!showPDF)}>
              <div className="background-image chartBtnImage" style={{ backgroundImage: `url(${ic_question})` }} />
              <span>Hướng dẫn</span>
            </div>
            {showPDF && (
              <>
                <div className="closePDF" onClick={() => setShowPDF(false)} />
                <object data={`./game_bo/guideline.pdf`} type="application/pdf" className="showPDF">
                  <embed src={`./game_bo/guideline.pdf`} type="application/pdf" style={{ width: '100%', height: '100%' }} />
                </object>
              </>
            )}
            <div className={`chartBtn ${!tokenParams && 'isDisable'}`} onClick={() => setShowHistory(true)}>
              <div className="background-image chartBtnImage" style={{ backgroundImage: `url(${ic_clockNew})` }} />
              <span>Lịch sử chơi</span>
            </div>
            <div
              className="chartVolume"
              onClick={() => {
                setShowMusic(!showMusic);
                handleChooseMusic(!showMusic);
              }}
            >
              <div
                className="background-image chartVolumeImage"
                style={{ backgroundImage: `url(${showMusic ? ic_volume : ic_volume_mute})` }}
              />
            </div>
          </div>
          <div className="chartContainer">
            <div className="chartContainerLeft">
              <div className="chartContainerLeftTop">
                <div className={'chartHeaderTop'}>
                  <div className="position-relative" ref={ref}>
                    <div
                      className={'chartHeaderTopContain'}
                      onClick={() => setIsOpenModalListCoin(!isOpenModalListCoin)}
                    >
                      <img
                        className={'imgCoin'}
                        src={
                          newCoinData?.find(item => item?.symbol === pair)?.icon_url ||
                          '/static/media/ic-BTC.06f0bf5d.svg'
                        }
                        alt=""
                      />
                      <span className={'name'}>
                        {newCoinData?.find(item => item?.symbol === pair)?.type_coin.replace('-', '/') || 'BTC/USD'}
                      </span>
                      <img className={'imgDown'} src={ic_arrowDown} alt="" />
                    </div>
                    {isOpenModalListCoin && (
                      <ModalListCoinChart
                        status={isOpenModalListCoin}
                        closeDrawer={() => setIsOpenModalListCoin(false)}
                        setIsOpenModalListCoin={setIsOpenModalListCoin}
                        listCoins={newCoinData}
                        handleChangeTypeCoin={handleChangeTypeCoin}
                        convertPercent={convertPercent}
                        priceNewCoin={priceNewCoin}
                        pair={pair}
                      />
                    )}
                  </div>

                  <div className="chartHeaderRight">
                    <div className="chartHeaderMA" onClick={() => setShowLineMA(!showLineMA)}>
                      <div
                        className="background-image chartVolumeImage"
                        style={{ backgroundImage: `url(${ic_diagram})`, marginRight: '8px' }}
                      />
                      <span>Đường MA</span>
                      {showLineMA && (
                        <div
                          className="background-image chartVolumeImage"
                          style={{ backgroundImage: `url(${ic_eye})`, marginLeft: '8px' }}
                        />
                      )}
                    </div>
                    <div className="chartHeaderMoney">
                      <div
                        className="background-image chartVolumeImage"
                        style={{ backgroundImage: `url(${ic_money})`, marginRight: '8px' }}
                      />
                      <span>
                        {tokenParams
                          ? currencyFormatUSD(
                              user?.wallets?.find(wallet => wallet?.walletType === 'PointWallet')?.balance || 0,
                            )
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <p className={'chartHeaderAmount'}>
                  {'$ '}
                  {priceNewCoin}
                </p>
                <p
                  className={`chartHeaderPercent ${
                    newCoinData?.find(item => item.symbol === pair)?.percent < 0 ? 'red' : 'green'
                  }`}
                >
                  {convertPercent(
                    newCoinData?.find(item => item.symbol === pair)?.percent
                      ? newCoinData?.find(item => item.symbol === pair)?.percent
                      : 0,
                  )}{' '}
                  %
                </p> */}
                <div style={{ height: '500px' }}>
                  <TradeViewChart pair={pair} interval={intervalChart} showLineMA={showLineMA} typeTimeChart={typeTimeChart} />
                </div>
              </div>
              <div className="chartContainerLeftBot">
                <div className="chartContainerLeftBotHead">
                  <div className="chartContainerLeftBotHeadContent">
                    <span style={{ color: '#36FFB5' }}>TĂNG </span>
                    <span>{countResultGameBo(resultGameBO, 'TANG')}</span>
                  </div>
                  <p className="chartContainerLeftBotHeadText">Kết quả mới nhất</p>
                  <div className="chartContainerLeftBotHeadContent">
                    <span style={{ color: '#FF715E' }}>GIẢM </span>
                    <span>{countResultGameBo(resultGameBO, 'GIAM')}</span>
                  </div>
                </div>
                <div className="chartResult">
                  {[...new Array(5)].map((item, index) => {
                    return (
                      <div
                        className={`chartResultElement ${
                          index === 0 ? 'horizontal' : index === 1 ? 'vertical' : index === 2 ? 'ipad' : ''
                        }`}
                        key={index}
                      >
                        {resultGameBO?.slice(index * 20, index * 20 + 20)?.map((item, indexchild) => {
                          return (
                            <div
                              key={`circle_${indexchild}`}
                              className={`chartResultCircle ${
                                item?.gameRecordResult
                                  ? item?.gameRecordResult === 'TANG'
                                    ? 'bgGreen'
                                    : 'bgRed'
                                  : 'bgBlack'
                              }`}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="chartContainerRight">
              <div className="chartContainerRightTop">
                <p className="chartContainerRightTitle">Giá trị (VNĐ)</p>
                <div className="chartContainerRightInput">
                  <input
                    type={'text'}
                    placeholder={'Nhập số tiền'}
                    value={money}
                    className="chartContainerRightInputLeft"
                    onKeyDown={e =>
                      ![
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '0',
                        'Backspace',
                        'ArrowLeft',
                        'ArrowRight',
                      ].includes(e.key) && e.preventDefault()
                    }
                    onChange={e => e.target.value?.length < 15 && setMoney(formatToPrice(e.target.value) || '')}
                  />
                  <img
                    alt=""
                    src={ic_close}
                    width={20}
                    className={'cursor-pointer'}
                    height={20}
                    onClick={() => setMoney('')}
                  />
                </div>
                <div className="buttonMoney">
                  <p
                    className="buttonMoneyElement"
                    onClick={() =>
                      handleAddMoney(user?.wallets?.find(wallet => wallet?.walletType === 'PointWallet').balance || 0)
                    }
                  >
                    Toàn bộ
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(1000000)}>
                    +1 triệu
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(2000000)}>
                    +2 triệu
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(5000000)}>
                    +5 triệu
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(10000000)}>
                    +10 triệu
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(20000000)}>
                    +20 triệu
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(50000000)}>
                    +50 triệu
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(100000000)}>
                    +100 triệu
                  </p>
                  <p className="buttonMoneyElement" onClick={() => handleAddMoney(200000000)}>
                    +200 triệu
                  </p>
                </div>
                <p className="chartContainerRightTitle">Lợi nhuận (VNĐ)</p>
                <div className="d-flex align-items-end">
                  <span className="textPercent">90%</span>
                  <span className="textMoney">
                    {formatToPrice((parseInt(money.replaceAll(',', '')) * 1.9).toFixed(0))}
                  </span>
                </div>
                <p className="chartContainerRightTitle">Chỉ báo tâm lý</p>
                <div className="lineRed">
                  <div className="lineGreen" style={{ width: `23%` }} />
                </div>
                <div className="d-flex justify-content-between">
                  <p className="textRed">77%</p>
                  <p className="textGreen">23%</p>
                </div>
              </div>
              <div className="chartContainerRightBot">
                <p className="textSection">
                  {f({ id: 'Period' })} {periodCode}
                </p>
                <div className="countDown">
                  <img
                    alt=""
                    src={ic_stop}
                    width={20}
                    className={'cursor-pointer'}
                    height={20}
                    onClick={() => setMoney('')}
                    style={{ marginRight: '4px' }}
                  />
                  <p>Đếm ngược: {countdown}s</p>
                </div>
                <button
                  className={`buttonPut bgGreen ${!tokenParams && 'isDisable'}`}
                  onClick={() => handleSelectCommand(true)}
                  disabled={countdown < 60}
                >
                  <p>TĂNG</p>
                  <img
                    alt=""
                    src={ic_up}
                    width={24}
                    className={'cursor-pointer'}
                    height={24}
                    onClick={() => setMoney('')}
                    style={{ marginLeft: '4px' }}
                  />
                </button>
                <button
                  className={`buttonPut bgRed ${!tokenParams && 'isDisable'}`}
                  onClick={() => handleSelectCommand(false)}
                  disabled={countdown <= 60}
                >
                  <p>GIẢM</p>
                  <img
                    alt=""
                    src={ic_down}
                    width={24}
                    className={'cursor-pointer'}
                    height={24}
                    onClick={() => setMoney('')}
                    style={{ marginLeft: '4px' }}
                  />
                </button>
              </div>
            </div>
          </div>

          <div className={'chartFooter'}>
            {showTicket !== '' && (
              <div className="ticket">
                <p className="ticketHead">PHIẾU ĐẶT</p>
                <div className="ticketSelect">
                  <p className="ticketSelectTitle">Bạn đang</p>
                  <p className="ticketSelectText">{`LỰA CHỌN ${showTicket ? 'TĂNG' : 'GIẢM'}`}</p>
                </div>
                <div className="chartContainerRightTop">
                  <p className="chartContainerRightTitle">Giá trị (VNĐ)</p>
                  <div className="chartContainerRightInput">
                    <input
                      type={'text'}
                      placeholder={'Nhập số tiền'}
                      value={money}
                      className="chartContainerRightInputLeft"
                      onKeyDown={e =>
                        ![
                          '1',
                          '2',
                          '3',
                          '4',
                          '5',
                          '6',
                          '7',
                          '8',
                          '9',
                          '0',
                          'Backspace',
                          'ArrowLeft',
                          'ArrowRight',
                        ].includes(e.key) && e.preventDefault()
                      }
                      onChange={e => e.target.value?.length < 15 && setMoney(formatToPrice(e.target.value) || '')}
                    />
                    <img
                      alt=""
                      src={ic_close}
                      width={20}
                      className={'cursor-pointer'}
                      height={20}
                      onClick={() => setMoney('')}
                    />
                  </div>
                  <div className="buttonMoney">
                    <p
                      className="buttonMoneyElement"
                      onClick={() =>
                        handleAddMoney(user?.wallets?.find(wallet => wallet?.walletType === 'PointWallet').balance)
                      }
                    >
                      Toàn bộ
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(1000000)}>
                      +1 triệu
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(2000000)}>
                      +2 triệu
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(5000000)}>
                      +5 triệu
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(10000000)}>
                      +10 triệu
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(20000000)}>
                      +20 triệu
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(50000000)}>
                      +50 triệu
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(100000000)}>
                      +100 triệu
                    </p>
                    <p className="buttonMoneyElement" onClick={() => handleAddMoney(200000000)}>
                      +200 triệu
                    </p>
                  </div>
                  <p className="chartContainerRightTitle">Lợi nhuận (VNĐ)</p>
                  <div className="d-flex align-items-end">
                    <span className="textPercent">95%</span>
                    <span className="textMoney">
                      {formatToPrice((parseInt(money.replaceAll(',', '')) * 1.95).toFixed(0))}
                    </span>
                  </div>
                  <p className="chartContainerRightTitle">Chỉ báo tâm lý</p>
                  <div className="lineRed">
                    <div className="lineGreen" style={{ width: `23%` }} />
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="textRed">77%</p>
                    <p className="textGreen">23%</p>
                  </div>
                </div>
              </div>
            )}
            <div className={'chartFooterContainer'}>
              <div className={'chartFooterTime'}>
                <span className={'period'}>
                  {f({ id: 'Period' })} {periodCode}
                </span>
                <div className={'count'}>
                  <img src={ic_stop} alt="" />
                  <span>
                    Countdown: {countdown}s
                  </span>
                </div>
              </div>
              {showTicket !== '' ? (
                <div className={'chartFooterButton'}>
                  <button className="chartFooterButtonClose" onClick={() => setShowTicket('')}>
                    HỦY
                  </button>
                  <button
                    className={`chartFooterButtonVerify ${!tokenParams && 'isDisable'}`}
                    onClick={() => handleSelectCommand(showTicket)}
                    disabled={countdown <= 60}
                  >
                    XÁC NHẬN ĐẶT
                  </button>
                </div>
              ) : (
                <div className={'chartFooterButton'}>
                  <button
                    className="chartFooterButtonLeft"
                    onClick={() => setShowTicket(true)}
                    disabled={countdown < 60}
                  >
                    <p>TĂNG</p>
                    <img
                      alt=""
                      src={ic_up}
                      width={24}
                      className={'cursor-pointer'}
                      height={24}
                      onClick={() => setMoney('')}
                      style={{ marginLeft: '4px' }}
                    />
                  </button>
                  <button
                    className="chartFooterButtonRight"
                    onClick={() => setShowTicket(false)}
                    disabled={countdown < 60}
                  >
                    <p>GIẢM</p>
                    <img
                      alt=""
                      src={ic_down}
                      width={24}
                      className={'cursor-pointer'}
                      height={24}
                      onClick={() => setMoney('')}
                      style={{ marginLeft: '4px' }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalShowBetLose
       // video={video_betlose}
        title={`Kỳ ${periodCode}`}
        content={'CHÚC BẠN MAY MẮN LẦN SAU'}
        showModalResult={showModalBetLose}
        closeModal={() => setShowModalBetLose(false)}
        setShowHistory={setShowHistory}
      />
      <ModalShowBetWin
        //video={video_betwin}
        title={`Xin chúc mừng`}
        content={`+ ${moneyWinLose} VNĐ`}
        showModalResult={showModalBetWin}
        closeModal={() => setShowModalBetWin(false)}
        setShowHistory={setShowHistory}
      />
      <ModalShowResult
        //video={video_betsuccess}
        title={`Kỳ ${periodCode}`}
        content={'ĐẶT LỆNH THÀNH CÔNG'}
        showModalResult={showModalResult}
        closeModal={() => setShowModalResult(false)}
        setShowHistory={setShowHistory}
      />
    </Page>
  );
};
export default injectIntl(DetailChart);
