/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { injectIntl, useIntl } from 'react-intl'
import TradeViewChart from 'components/Chart/tradeViewChart'
import { useUser } from '../../../context/UserContext'
import './styles/detail-chart.scss'
import GameInfo from '../../../services/gameInfo'
//icon
import icChip5 from '../../../assets/bo-icons/ic_chip_5.svg'
import icChip10 from '../../../assets/bo-icons/ic_chip_10.svg'
import icChip25 from '../../../assets/bo-icons/ic_chip_25.svg'
import icChip50 from '../../../assets/bo-icons/ic_chip_50.svg'
import icChip100 from '../../../assets/bo-icons/ic_chip_100.svg'
import icChip200 from '../../../assets/bo-icons/ic_chip_200.svg'
import icChip500 from '../../../assets/bo-icons/ic_chip_500.svg'
import icChip1000 from '../../../assets/bo-icons/ic_chip_1000.svg'
import icChip2000 from '../../../assets/bo-icons/ic_chip_2000.svg'
import icChip5000 from '../../../assets/bo-icons/ic_chip_5000.svg'
import icChip8000 from '../../../assets/bo-icons/ic_chip_8000.svg'
import icChip10000 from '../../../assets/bo-icons/ic_chip_10000.svg'
import icChip15000 from '../../../assets/bo-icons/ic_chip_15000.svg'
import icChip20000 from '../../../assets/bo-icons/ic_chip_20000.svg'
import icChipAll from '../../../assets/bo-icons/ic_chip_all.svg'
import icChipAdd from '../../../assets/bo-icons/ic_chip_add.svg'
import icCoin from '../../../assets/bo-icons/ic_coin.svg'
import ic_arrowDown from '../../../assets/bo-icons/ic_arrow_down.svg'
import ic_stop from '../../../assets/bo-icons/ic_stop_watch.svg'
import ic_clockNew from '../../../assets/bo-icons/ic_clock.svg'
import ic_history_disable from '../../../assets/bo-icons/ic_history_disable.svg'
import ic_increase from '../../../assets/bo-icons/ic_Course_Up_bold.svg'
import ic_decrease from '../../../assets/bo-icons/ic_Course_Down_bold.svg'
import ic_eye from '../../../assets/bo-icons/ic-eye.svg'
// import video_betsuccess from '/public/game_bo/videos/video_betsuccess.mp4';
// import video_betwin from '/public/game_bo/videos/video_betwin.mp4';
// import video_betlose from '/public/game_bo/videos/video_betlose.mp4';
import ModalListCoinChart from '../../../components/Modal/components/ModalListCoinChart/ModalListCoinChart'
import DetailCoinService from 'services/detailCoin'
import moment from 'moment'
import AppUsers from '../../../services/apppUsers'
import { getGameRecordUserGetList, handleUpdateDetail } from '../../../actions'
import { BINANCE_PARAMS, COIN_LIST } from '../../../constants/listCoin'
import { formatToPrice } from 'helper/common'
import ModalShowResult from 'components/Modal/components/ModalShowResult/ModalShowResult'

import GameRecordService from 'services/gameRecordService'
import ModalShowBetLose from 'components/Modal/components/ModalShowBetLose/ModalShowBetLose'
import ModalShowBetWin from 'components/Modal/components/ModalShowBetWin/ModalShowBetWin'
import ModalSelectChip from 'components/Modal/components/ModalSelectChip/ModalSelectChip'
// import { SOCKET_WSS, SOCKET_WS } from 'constants/url'
import useWindowDimensions from 'hooks/useWindowDimensions'
import LayoutAccount from '../../Layout/layoutAccount'
import swal from 'sweetalert'
import ic_close from '../../../assets/bo-icons/ic_Close_Circle.svg'
import ic_repeat from '../../../assets/bo-icons/ic_Repeat_One.svg'
import ic_candle from '../../../assets/bo-icons/ic_candle_chart.svg'
import ic_eye_white from '../../../assets/bo-icons/ic_eye_white.svg'
import ic_candle2 from '../../../assets/bo-icons/ic_candle_chart_2.svg'
import ic_check from '../../../assets/bo-icons/ic_check.svg'
import currencyFormat from '../../../ultils/CurrencyFormat'


const listTypeTimeChart = [
  {
    name: 60,
    type: 'BINARYOPTION_UPDOWN'
  },
  {
    name: 15,
    type: 'BINARYOPTION_UPDOWN_15S'
  },
  {
    name: 5,
    type: 'BINARYOPTION_UPDOWN_5S'
  }
]
const DetailChartV2 = (props) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const refTypeChart = useRef(null)
  const refTypeTime = useRef(null)
  const searchParams = new URLSearchParams(useLocation().search)
  const [pair, setPair] = useState(searchParams?.get('type') ? searchParams?.get('type') : 'BTCUSDT')
  const intervalChart = searchParams?.get('interval') ? searchParams?.get('interval') : '1m'
  const [isOpenModalListCoin, setIsOpenModalListCoin] = useState(false)
  const { user } = useUser()
  const [coinData, setCoinData] = useState(COIN_LIST)
  const [newCoinData, setNewCoinData] = useState(COIN_LIST)
  const [countdown, setCountDown] = useState(0)
  const [moneyWinLose, setMoneyWinLose] = useState(0)
  const [priceNewCoin, setPriceNewCoin] = useState(0)
  const [showModalResult, setShowModalResult] = useState(false)
  const [showModalBetWin, setShowModalBetWin] = useState(false)
  const [showModalBetLose, setShowModalBetLose] = useState(false)
  const [showModalSelectChip, setShowModalSelectChip] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const data = useSelector((state) => (state.member ? state.member : null))
  const [resultGameBO, setResultGameBO] = useState()
  const [resultGameBOV2, setResultGameBOV2] = useState()
  // const [indexResultGameBOV2, setIndexResultGameBOV2] = useState();
  const [newResult, setNewResult] = useState()
  const [select, setSelect] = useState(true)
  const [chipIncrease, setChipIncrease] = useState(0)
  const [chipReduce, setChipReduce] = useState(0)
  const [totalIncrease, setTotalIncrease] = useState(0)
  const [totalReduce, setTotalReduce] = useState(0)
  const [newDataReduce, setNewDataReduce] = useState(0)
  const [newDataIncrease, setNewDataIncrease] = useState(0)
  const [isShowTypeChart, setIsShowTypeChart] = useState(false)
  const [typeChartShowed, setTypeChartShowed] = useState('candle')
  const [checkPlayRecordThisTurn, setCheckPlayRecordThisTurn] = useState(false)
  const [typeTimeChart, setTypeTimeChart] = useState(
    searchParams?.get('typeTimeChart')
      ? listTypeTimeChart.find((item) => item.name + '' === searchParams?.get('typeTimeChart'))
      : listTypeTimeChart[0]
  )
  const [isShowTypeTime, setIsShowTypeTime] = useState(false)
  const [dataNewChartGameRecord, setDataNewChartGameRecord] = useState([])
  // const [historyIncrease, setHistoryIncrease] = useState([]);
  // const [historyReduce, setHistoryReduce] = useState([]);
  const [repeat, setRepeat] = useState({ increase: 0, reduce: 0 })
  const socketUrl = 'wss://stream.binance.com:9443/ws'
  const [periodCode, setPeriodCode] = useState(0)
  const [showLineMA, setShowLineMA] = useState(true)
  const [bookTime, setBookTime] = useState(false)
  const [dataGameInfo, setDataGameInfo] = useState(undefined)
  const [dataServerTimeSocket, setDataSerVerTimeSocket] = useState(0)
  const { width } = useWindowDimensions()
  const [dataChip, setDataChip] = useState([
    { id: 'chip_5', icon: icChip5, title: '5,000', value: 5000, show: true },
    { id: 'chip_10', icon: icChip10, title: '10,000', value: 10000, show: true },
    { id: 'chip_25', icon: icChip25, title: '25,000', value: 25000, show: true },
    { id: 'chip_50', icon: icChip50, title: '50,000', value: 50000, show: true },
    { id: 'chip_100', icon: icChip100, title: '100,000', value: 100000, show: false },
    { id: 'chip_200', icon: icChip200, title: '200,000', value: 200000, show: false },
    { id: 'chip_500', icon: icChip500, title: '500,000', value: 500000, show: false },
    {
      id: 'chip_1000',
      icon: icChip1000,
      title: `1 ${f({ id: 'milion' })}`,
      value: 1000000,
      show: false
    },
    { id: 'chip_2000', icon: icChip2000, title: `2 ${f({ id: 'milion' })}`, value: 2000000, show: false },
    { id: 'chip_5000', icon: icChip5000, title: `5 ${f({ id: 'milion' })}`, value: 5000000, show: false },
    { id: 'chip_8000', icon: icChip8000, title: `8 ${f({ id: 'milion' })}`, value: 8000000, show: false },
    { id: 'chip_10000', icon: icChip10000, title: `10 ${f({ id: 'milion' })}`, value: 10000000, show: false },
    { id: 'chip_15000', icon: icChip15000, title: `15 ${f({ id: 'milion' })}`, value: 15000000, show: false },
    { id: 'chip_20000', icon: icChip20000, title: `20 ${f({ id: 'milion' })}`, value: 20000000, show: false },
    {
      id: 'chip_all',
      icon: icChipAll,
      title: f({ id: 'Bet_the_full_amount_in_the_wallet' }),
      value: user?.wallets?.find((wallet) => wallet?.walletType === 'PointWallet').balance || 0,
      show: false
    }
  ])
  const ref = useRef()
  function handleChangeTypeCoin(pair) {
    setPair(pair)
    setIsOpenModalListCoin(false)
    checkParams({ type: pair, typeTimeChart: typeTimeChart.name })
  }
  const checkParams = (filterParams) => {
    const params = {}
    if (filterParams?.type) {
      params.type = filterParams.type
    }
    if (filterParams?.typeTimeChart) {
      params.typeTimeChart = filterParams.typeTimeChart
    }
    if (searchParams.get('token')) {
      params.token = searchParams.get('token')
    }
    const urlSearchParams = new URLSearchParams(params)
    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() })
  }
  useEffect(() => {
    getGameInfo()
  }, [pair])

  useEffect(() => {
    getListResult()
  }, [typeTimeChart.type, pair])

  const getDataWebSocket = () => {
    const ws = new WebSocket(socketUrl)
    let isCheck = false
    ws.onopen = () => {
      const params = {
        method: 'SUBSCRIBE',
        params: BINANCE_PARAMS,
        id: 1
      }
      ws.send(JSON.stringify(params))
    }
    ws.onmessage = (evt) => {
      const data = evt?.data ? JSON.parse(evt?.data) : {}
      if (data?.s) {
        for (let coin of coinData) {
          if (coin?.symbol === data?.s) {
            coin.price = Number(data?.c)
            coin.percent = data?.P
          }
        }
        setCoinData(coinData)
        if (!isCheck) {
          isCheck = true
          setNewCoinData([...coinData])
        }
      }
    }
    ws.onclose = () => {}
    return () => {
      ws.close()
    }
  }
  const convertPercent = (number) => {
    if (number !== undefined && number !== null) {
      return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    } else return 0
  }

  function handleApiPlaceRecord(params) {
    DetailCoinService.gamePlaceRecord(params).then((result) => {
      const { isSuccess } = result
      if (isSuccess) {
        handleReload()
        setShowModalResult(true)
      } else {
        // setIsOpenModalConfirmCommonUse(true)
        // setDataContentModal(f({ id: 'Place_order_failed' }))
        swal(f({ id: 'Place_order_failed' }), {
          icon: 'warning',
          dangerMode: true,
          buttons: [`${f({ id: 'Back' })}`, `${f({ id: 'Agree' })}`]
        })
      }
    })
  }

  useEffect(() => {
    dispatch(getGameRecordUserGetList(dataNewChartGameRecord))
  }, [dataNewChartGameRecord])
  useEffect(() => {
    if (newResult && newResult?.gameRecordType === typeTimeChart.type && resultGameBO) {
      const index = resultGameBO?.findIndex((item) => !item?.gameRecordResult)
      if (index === 143) {
        getListResult()
      } else {
        resultGameBO[index] = newResult
        setResultGameBO([...resultGameBO])
        getResultGameBoV2(resultGameBO)
        setDataNewChartGameRecord([...dataNewChartGameRecord, newResult])
      }
    }
  }, [newResult])
  useEffect(() => {
    if (newDataIncrease?.betRecordAmountIn) {
      setTotalIncrease(totalIncrease + newDataIncrease.betRecordAmountIn)
    }
  }, [newDataIncrease])
  useEffect(() => {
    if (newDataReduce?.betRecordAmountIn) {
      setTotalReduce(totalReduce + newDataReduce.betRecordAmountIn)
    }
  }, [newDataReduce])

  const handleClick = (e) => {
    if (!ref.current?.contains(e.target)) setIsOpenModalListCoin(false)
    // if (!refProfile.current?.contains(e.target)) setShowProfile(false);
  }
  useEffect(() => {
    getDataWebSocket()
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  function handleReload() {
    getDetailUserById()
  }
  function getDetailUserById() {
    if (data?.appUserId)
      AppUsers.getDetailUser()
        .then((result) => {
          const { isSuccess, data } = result
          if (isSuccess) {
            dispatch(handleUpdateDetail(data))
          }
        })
        .catch(() => {})
  }

  const handleSetCommand = () => {
    const amountWallet = parseFloat(user?.wallets?.find((wallet) => wallet?.walletType === 'PointWallet').balance)
    if (amountWallet === 0) {
      swal(f({ id: 'Balance_not_available' }), {
        icon: 'warning',
        dangerMode: true,
        buttons: [`${f({ id: 'Back' })}`, `${f({ id: 'Agree' })}`]
      })
      return
    }
    if (chipIncrease + chipReduce < 50000) {
      swal(`${f({ id: 'Cancel_the_order_because_the_deposit_is_below_the_minimum!' })} (50,000)`, {
        icon: 'warning',
        dangerMode: true,
        buttons: [`${f({ id: 'Back' })}`, `${f({ id: 'Agree' })}`]
      })
      return
    }
    if (chipIncrease + chipReduce > 100000000) {
      swal(`${f({ id: 'Cancel_the_order_because_the_deposit_is_above_the_maximum!' })} (100,000,000)`, {
        icon: 'warning',
        dangerMode: true,
        buttons: [`${f({ id: 'Back' })}`, `${f({ id: 'Agree' })}`]
      })
      return
    }
    if (bookTime) {
      if (chipIncrease) {
        const params = {
          betRecordAmountIn: chipIncrease,
          betRecordType: typeTimeChart.type,
          betRecordValue: 'TANG'
        }
        handleApiPlaceRecord(params)
      }
      if (chipReduce) {
        const params = {
          betRecordAmountIn: chipReduce,
          betRecordType: typeTimeChart.type,
          betRecordValue: 'GIAM'
        }
        handleApiPlaceRecord(params)
      }
      // setHistoryIncrease([]);
      // setHistoryReduce([]);
      setRepeat({
        increase: chipIncrease,
        reduce: chipReduce
      })
      setChipIncrease(0)
      setChipReduce(0)
    }
  }

  const getListResult = () => {
    const params = {
      filter: {
        gameRecordType: typeTimeChart.type,
        gameRecordUnit: COIN_LIST?.find((item) => item?.symbol === pair)?.type_place_record || 'BTC-USDT'
      },
      skip: 0,
      limit: 100
    }
    GameRecordService.getList(params).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        const fulldata = data?.data?.reverse()
        setResultGameBO([...fulldata, ...new Array(44)]);
        setDataNewChartGameRecord(fulldata)
        dispatch(getGameRecordUserGetList(fulldata))
        getResultGameBoV2(fulldata)
      }
    })
  }

  const getGameInfo = () => {
    const params = {
      filter: {}
    }
    GameInfo.getListGameInfo(params).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        setDataGameInfo(data.data.find((item) => item.gameInfoId === 8))
      }
    })
  }

  const getResultGameBoV2 = (data) => {
    const arrNew = [...new Array(10000)]
    let index = 0
    let index2 = 0
    let indexLast = 1
    let lastResult = undefined

    data.map((item) => {
      const result = item?.gameRecordResult === 'HOA' ? lastResult : item?.gameRecordResult
      if (!lastResult && item?.gameRecordResult && item?.gameRecordResult !== 'HOA') {
        lastResult = result
        arrNew[index] = item
      }
      if (result) {
        if (lastResult !== result) {
          lastResult = result
          index = index + (6 - (index % 6))
          index2 = index
          arrNew[index] = item
        } else {
          lastResult = result
          if (index % 6 === 0 && !arrNew[index + 1]) {
            index = index + 1
            index2 = index
            arrNew[index2] = item
          } else if (index % 6 === 0 && arrNew[index + 1]) {
            if (index2 % 6 !== 5) {
              index2 = index2 + 1
            } else {
              index2 = index2 + 6
            }
            arrNew[index2] = item
          } else if (index % 6 !== 5 && !arrNew[index + 1]) {
            index = index + 1
            index2 = index
            arrNew[index] = item
          } else if (index % 6 !== 5 && arrNew[index + 1]) {
            index2 = index2 + 6
            arrNew[index2] = item
          } else if (index % 6 === 5 && !arrNew[index]) {
            arrNew[index] = item
          } else if (index % 6 === 5 && arrNew[index]) {
            index2 = index2 + 6
            arrNew[index2] = item
          }
        }
      }
    })
    arrNew.forEach((element, index) => {
      if (element) {
        indexLast = index
      }
    })
    const arrResult = arrNew.splice(0, indexLast + 1)
    setResultGameBOV2([...arrResult])
  }

  const handleAddChip = (value) => {
    const amountWallet = user?.wallets?.find((wallet) => wallet?.walletType === 'PointWallet').balance
    // if (!bookTime) {
    //   // setIsOpenModalConfirmCommonUse(true)
    //   // setDataContentModal('Vui lòng đợi đến thời gian đặt')
    //   swal(f({ id: 'Vui lòng đợi đến thời gian đặt' }), {
    //     icon: 'warning',
    //     dangerMode: true,
    //     buttons: [`${f({ id: 'Back' })}`, `${f({ id: 'Agree' })}`]
    //   })
    //   return
    // }
    if (select) {
      if (chipIncrease + chipReduce + value > amountWallet) {
        setChipIncrease(chipIncrease + (amountWallet - (chipIncrease + chipReduce)))
      } else {
        setChipIncrease(chipIncrease + value)
      }
    } else {
      if (chipIncrease + chipReduce + value > amountWallet) {
        setChipReduce(chipReduce + (amountWallet - (chipIncrease + chipReduce)))
      } else {
        setChipReduce(chipReduce + value)
      }
    }
  }
  const handleCancel = () => {
    if (select) {
      setChipIncrease(repeat.increase)
    } else {
      setChipReduce(repeat.reduce)
    }
  }
  const handleRepeat = () => {
    if (bookTime) {
      setChipIncrease(chipIncrease + repeat.increase)
      setChipReduce(chipReduce + repeat.reduce)
    }
  }

  const handleAnimationDesktop = (e, id_chip, value) => {
    if (chipIncrease + chipReduce + value > user?.wallets?.find((wallet) => wallet?.walletType === 'PointWallet').balance) {
      return
    }
    // if (!bookTime) return
    const container = document.getElementById('increase')
    const chip = e.target.cloneNode(true)
    chip.classList.add('animation')
    chip.animate(
      [
        { transform: 'translate(0, 0)' },
        {
          transform: `translate(${
            container.offsetLeft + container.offsetWidth / 2 - e.target.parentElement.offsetLeft - e.target.offsetWidth / 2
          }px, ${select ? -325 : -135}px)`
        }
      ],
      {
        duration: 500
      }
    )
    document.getElementById(id_chip).appendChild(chip)
    setTimeout(() => {
      chip.remove()
    }, 500)
  }
  const handleAnimationMobile = (e, id_chip, checkLine, value) => {
    if (chipIncrease + chipReduce + value > user?.wallets?.find((wallet) => wallet?.walletType === 'PointWallet').balance) {
      return
    }
    // if (!bookTime) return
    const chip = e.target.cloneNode(true)
    const container = select ? document.getElementById('increase_mobile') : document.getElementById('reduce_mobile')
    chip.classList.add('animation')
    chip.animate(
      [
        { transform: 'translate(0, 0)' },
        {
          transform: `translate(${
            container.offsetLeft + container.offsetWidth / 2 - e.target.parentElement.offsetLeft - e.target.offsetWidth / 2
          }px, ${-120 + (checkLine ? 0 : -60)}px)`
        }
      ],
      {
        duration: 500
      }
    )
    document.getElementById(id_chip).appendChild(chip)
    setTimeout(() => {
      chip.remove()
    }, 500)
  }
  function countResultGameBo(array = [], value) {
    if (array?.length > 0) {
      return array?.filter((item) => item?.gameRecordResult === value)?.length
    }
    return 0
  }
  function handleCheckWinRate(data, updown, type) {
    if (data) {
      const winRate = data?.gameConfigWinRate?.find((item) => item.gameRecordResultType === (updown ? 'TANG' : 'GIAM')).winRate
      if (type === 'percent') {
        return ((parseFloat(winRate) - 1) * 100).toFixed() + '%'
      }
      if (type === 'amount') {
        return parseFloat(winRate)
      }
    }
  }
  useEffect(() => {
    document.querySelector('.chartResultLeft').scrollLeft += 1000
    document.querySelector('.chartResultRight').scrollLeft += 1000
  }, [resultGameBO, resultGameBOV2])

  useEffect(() => {
    function handleClickOutside(event) {
      if (!refTypeChart.current.contains(event.target)) {
        setIsShowTypeChart(false)
      }
      if (!refTypeTime.current.contains(event.target)) {
        setIsShowTypeTime(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  function handleCheckBookTime(rawTime, typeTimeChart) {
    const secondFromSocket = parseInt(moment(rawTime, 'YYYYMMDDHHmmss').format('ss'))
    const minuteFromSocket = parseInt(moment(rawTime, 'YYYYMMDDHHmmss').format('mm'))
    let bookTime = false
    let countDown = 0

    switch (typeTimeChart.name) {
      case 60:
        if (minuteFromSocket % 2 === 0) {
          bookTime = true
        } else {
          setTotalIncrease(0)
          setTotalReduce(0)
        }
        countDown = 60 - secondFromSocket
        break

      case 15:
        if ((0 <= secondFromSocket && secondFromSocket <= 14) || (30 <= secondFromSocket && secondFromSocket <= 44)) {
          bookTime = true
        } else {
          setTotalIncrease(0)
          setTotalReduce(0)
        }
        countDown = 15 - (secondFromSocket % 15)
        break

      case 5:
        if (
          (0 <= secondFromSocket && secondFromSocket <= 4) ||
          (10 <= secondFromSocket && secondFromSocket <= 14) ||
          (20 <= secondFromSocket && secondFromSocket <= 24) ||
          (30 <= secondFromSocket && secondFromSocket <= 34) ||
          (40 <= secondFromSocket && secondFromSocket <= 44) ||
          (50 <= secondFromSocket && secondFromSocket <= 54)
        ) {
          bookTime = true
        } else {
          setTotalIncrease(0)
          setTotalReduce(0)
        }
        countDown = 5 - (secondFromSocket % 5)
        break

      default:
        break
    }
    return { bookTime: bookTime, countDown: countDown }
  }
  useEffect(() => {
    const dataCheck = handleCheckBookTime(dataServerTimeSocket, typeTimeChart)
    setBookTime(dataCheck.bookTime)

    setCountDown(dataCheck.countDown)
  }, [dataServerTimeSocket])
  useEffect(() => {
    if (typeTimeChart.name !== 5) {
      if (bookTime) {

      } else {
        if (!checkPlayRecordThisTurn) {
          setTimeout(() => {
          }, 1500)
        } else {
        }
        setCheckPlayRecordThisTurn(false)
      }
    }
  }, [bookTime])
  return (
    <>
      <LayoutAccount>
        <div id={'Chart'}>
          <div className="chart">
            <div className="chartContainer">
              <div className="chartContainerLeft">
                <div className="chartContainerLeftTop">
                  <div className={'chartHeaderTop'}>
                    <div className="position-relative" ref={ref}>
                      <div
                        className={'chartHeaderTopContain'}
                        // onClick={() => setIsOpenModalListCoin(!isOpenModalListCoin)}
                      >
                        <img
                          className={'imgCoin'}
                          src={newCoinData?.find((item) => item?.symbol === pair)?.icon_url || '/static/media/ic-BTC.06f0bf5d.svg'}
                          alt=""
                        />
                        <span className={'name'}>
                          {newCoinData?.find((item) => item?.symbol === pair)?.type_coin.replace('-', '/') + 'T' || 'BTC/USDT'}
                        </span>
                        <img className={'imgDown'} src={ic_arrowDown} alt="" />
                      </div>
                      {isOpenModalListCoin && (
                        <ModalListCoinChart
                          status={isOpenModalListCoin}
                          closeDrawer={() => setIsOpenModalListCoin(false)}
                          setIsOpenModalListCoin={setIsOpenModalListCoin}
                          listCoins={newCoinData}
                          handleChangeTypeCoin={handleChangeTypeCoin}
                          convertPercent={convertPercent}
                          priceNewCoin={priceNewCoin}
                          pair={pair}
                        />
                      )}
                    </div>
                    <div className="chartHeaderRight">
                      <div className="chartHeaderTypeTime">
                        <span className="title">Thời gian</span>
                        {listTypeTimeChart.map((item, index) => (
                          <span
                            className={`time ${typeTimeChart.name === item.name ? 'activeTime' : ''}`}
                            key={index}
                            onClick={() => {
                              setTypeTimeChart(item)
                              checkParams({ type: pair, typeTimeChart: item.name })
                            }}>
                            {item.name}s
                          </span>
                        ))}
                        <div className="timeMobile">
                          <div
                            className="timeMobileBtn"
                            onClick={() => {
                              setIsShowTypeTime(true)
                            }}>
                            <span>{typeTimeChart.name} s</span>
                          </div>
                          <div ref={refTypeTime}>
                            {isShowTypeTime && (
                              <div className="timeMobileSelect">
                                {listTypeTimeChart.map((item, index) => (
                                  <div
                                    className={`timeMobileSelectItem ${typeTimeChart.name === item.name ? 'active' : ''}`}
                                    key={index}
                                    onClick={() => {
                                      setTypeTimeChart(item)
                                      setIsShowTypeTime(false)
                                      checkParams({ type: pair, typeTimeChart: item.name })
                                    }}>
                                    {typeTimeChart.name === item.name ? <img src={ic_check} alt="" className="img" /> : <div className="img" />}
                                    <span>
                                      {item.name} {f({ id: 'second' })}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="chartHeaderTypeChart" onClick={() => setIsShowTypeChart(!isShowTypeChart)}>
                        <img className="chartHeaderTypeChartIcon" src={ic_candle} alt="" />
                        <div ref={refTypeChart}>
                          {isShowTypeChart && (
                            <div className="chartHeaderTypeChartSelect">
                              {/* <div
                              className={`chartHeaderTypeChartSelectItem ${typeChartShowed === 'line' ? 'active' : ''}`}
                              onClick={() => setTypeChartShowed('line')}>
                              {typeChartShowed === 'line' ? <img src={ic_check} alt="" className="img" /> : <div className="img" />}
                              <span>Biểu đồ đường</span>
                              <img src={ic_candle1} alt="" />
                            </div> */}
                              <div
                                className={`chartHeaderTypeChartSelectItem ${typeChartShowed === 'candle' ? 'active' : ''}`}
                                onClick={() => setTypeChartShowed('candle')}>
                                {typeChartShowed === 'candle' ? <img src={ic_check} alt="" className="img" /> : <div className="img" />}
                                <span>Biểu đồ nến</span>
                                <img src={ic_candle2} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="chartHeaderMA cursor-pointer" onClick={() => setShowLineMA(!showLineMA)}>
                        <span className={`${showLineMA ? 'textOrange' : ''}`}>MA</span>
                        <div
                          className="background-image chartHeaderMAEye"
                          style={{
                            backgroundImage: `url(${showLineMA ? ic_eye : ic_eye_white})`,
                            width: '20px',
                            height: '20px',
                            marginLeft: '4px'
                          }}
                        />
                      </div>
                      <button
                        title={f({ id: 'history_play' })}
                        disabled={!user?.token}
                        className="chartHeaderMA cursor-pointer"
                        onClick={() => user?.token && setShowHistory(true)}>
                        <div
                          className="background-image"
                          style={{
                            backgroundImage: `url(${!user?.token || !bookTime ? ic_history_disable : ic_clockNew})`,
                            width: '24px',
                            height: '24px'
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  <div
                    className="chartBackgroundChart"
                    style={{
                      backgroundImage: `url(./game_bo/bo-image/${width > 769 ? 'backgroundChartDesktop.png' : 'backgroundChartMobile.png'})`
                    }}>
                    {!bookTime && <p className="textPlaceOrder">{bookTime ? f({ id: 'pleaseMakeAReservation' }) : f({ id: 'waitingForResults' })}</p>}
                     <TradeViewChart pair={pair} interval={intervalChart} showLineMA={showLineMA} typeTimeChart={typeTimeChart} />
                  </div>
                </div>
                <div className={'chartFooter'}>
                  <div className={'chartFooterContainer'}>
                    <div className="chartFooterTop">
                      <div className="d-flex justify-content-between">
                        <button
                          className={`buttonPut position-relative bgGreen2 ${(!user?.token || !bookTime) && 'isDisableGrey'}`}
                          onClick={() => setSelect(true)}
                          id="increase_mobile">
                          {chipIncrease > 0 && (
                            <div className="buttonPutMoney">
                              <div className="buttonPutMoneyChild">
                                <p>{formatToPrice(chipIncrease)}</p>
                              </div>
                            </div>
                          )}

                          <span>{f({ id: 'increase' })}</span>
                          <img src={ic_increase} alt="" height={20} width={20} style={{ margin: '0 0 4px 3px' }} />
                        </button>
                        <div className="countDown">
                          {/* <p className="textSection">{periodCode}</p> */}
                          {/* <p className="countDownText">{bookTime ? f({ id: 'pleaseMakeAReservation' }) : f({ id: 'waitingForResults' })}</p> */}
                          <p className="countDownNumber">
                            <img alt="" src={ic_stop} width={20} className={'cursor-pointer'} height={20} /> {countdown} S
                          </p>
                        </div>
                        <button
                          className={`buttonPut position-relative bgRed2 ${(!user?.token || !bookTime) && 'isDisableGrey'}`}
                          onClick={() => setSelect(false)}
                          id="reduce_mobile">
                          {chipReduce > 0 && (
                            <div className="buttonPutMoney">
                              <div className="buttonPutMoneyChild">
                                <p>{formatToPrice(chipReduce)}</p>
                              </div>
                            </div>
                          )}
                          <span>{f({ id: 'reduce' })}</span>
                          <img src={ic_decrease} alt="" height={20} width={20} style={{ margin: '0 0 4px 3px' }} />
                        </button>
                      </div>
                      <div className="amountProfitMobile">
                        <p className="amountProfitMobileDescription">
                          {f({ id: 'Profit' })} (VNĐ) <span>{handleCheckWinRate(dataGameInfo, select, 'percent')}</span>
                        </p>
                        <p className="amountProfitMobileAmount">
                          +{' '}
                          {currencyFormat(
                            Math.abs(
                              chipIncrease * handleCheckWinRate(dataGameInfo, true, 'amount') -
                                chipReduce * handleCheckWinRate(dataGameInfo, false, 'amount')
                            ) || 0
                          )}
                        </p>
                      </div>
                      <div className="lineRed">
                        <div
                          className="lineGreen"
                          style={{
                            width: `${totalIncrease || totalReduce ? (totalIncrease * 100) / (totalIncrease + totalReduce) : 50}%`
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="textRed d-flex align-items-center">
                          <img alt="" src={icCoin} width={12} className={'cursor-pointer'} height={12} style={{ marginRight: '4px' }} />
                          {formatToPrice(totalReduce)}
                        </p>
                        <p className="textGreen d-flex align-items-center">
                          <img alt="" src={icCoin} width={12} className={'cursor-pointer'} height={12} style={{ marginRight: '4px' }} />
                          {formatToPrice(totalIncrease)}
                        </p>
                      </div>
                    </div>
                    <div className="chartFooterBorder">
                      <div className="chartContainerRightTop">
                        <div className={`d-flex justify-content-between align-items-center ${(!user?.token || !bookTime) && 'isDisable'}`}>
                          {dataChip
                            .filter((item) => item.show)
                            .map((item, index) => {
                              return (
                                <div
                                  className={`position-relative`}
                                  style={{
                                    width: '19.27710843373494%',
                                    cursor: 'pointer',
                                    boxShadow: '0px 0px 10px 0px rgba(94,94,94,1)',
                                    borderRadius: '50%'
                                  }}
                                  id={`${item.id}_mobile`}
                                  key={index}>
                                  <img
                                    alt=""
                                    src={item.icon}
                                    style={{ width: '100%', cursor: 'pointer' }}
                                    onClick={(e) => {
                                      handleAddChip(item.value)
                                      handleAnimationMobile(e, `${item.id}_mobile`, item.value)
                                    }}
                                  />
                                </div>
                              )
                            })}
                          <div style={{ height: '24px', width: '1px', background: '#24345A' }} />
                          <div
                            className="position-relative"
                            style={{
                              width: '19.4578313253012%',
                              cursor: 'pointer',
                              boxShadow: '0px 0px 10px 0px rgba(94,94,94,1)',
                              borderRadius: '50%'
                            }}>
                            <img alt="" src={icChipAdd} style={{ width: '100%', cursor: 'pointer' }} onClick={() => setShowModalSelectChip(true)} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="chartContainerRightBot">
                      <div className="d-flex justify-content-between" style={{ gap: '4px' }}>
                        <button
                          className={`${(!user?.token || !bookTime) && 'isDisableGrey'} btnCancel`}
                          onClick={() => {
                            setChipIncrease(0)
                            setChipReduce(0)
                          }}>
                          <img src={ic_close} alt="" style={{ marginRight: '4px' }} />
                          {f({ id: 'cancel' })}
                        </button>
                        <button className={`${(!user?.token || !bookTime) && 'isDisableGrey'} btnCancel`} onClick={() => handleRepeat()}>
                          <img src={ic_repeat} alt="" style={{ marginRight: '4px' }} />
                          {f({ id: 'repeat' })}
                        </button>
                        <button
                          className={`btnCancel ${(!user?.token || !bookTime) && 'isDisableGrey'}`}
                          onClick={() => {
                            handleSetCommand()
                            setCheckPlayRecordThisTurn(true)
                          }}
                          disabled={!bookTime}
                          style={{ background: 'linear-gradient(180deg, #FC8B28 0%, #FF5857 100%)' }}>
                          {f({ id: 'confirm' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chartContainerRight">
                <div className="chartContainerRightTop">
                  <button
                    className={`buttonPut position-relative bgGreen2 ${(!user?.token || !bookTime) && 'isDisableGrey'}`}
                    onClick={() => setSelect(true)}
                    id="increase">
                    {chipIncrease > 0 && (
                      <div className="buttonPutMoney">
                        <div className="buttonPutMoneyChild">
                          <p>{formatToPrice(chipIncrease)}</p>
                        </div>
                      </div>
                    )}
                    <span>{f({ id: 'increase' })}</span>
                    <img src={ic_increase} alt="" height={24} width={24} style={{ margin: '0 0 4px 4px' }} />
                  </button>
                  <div className="countDown">
                    {/* <p className="textSection">
                      {f({ id: 'Period' })} {periodCode}
                    </p> */}
                    <p className="countDownText">{bookTime ? f({ id: 'pleaseMakeAReservation' }) : f({ id: 'waitingForResults' })}</p>
                    <p className="countDownNumber">
                      <img alt="" src={ic_stop} width={20} className={'cursor-pointer'} height={20} /> {countdown} S
                    </p>
                  </div>
                  <button
                    className={`buttonPut position-relative bgRed2 ${(!user?.token || !bookTime) && 'isDisableGrey'}`}
                    onClick={() => setSelect(false)}>
                    {chipReduce > 0 && (
                      <div className="buttonPutMoney">
                        <div className="buttonPutMoneyChild">
                          <p>{formatToPrice(chipReduce)}</p>
                        </div>
                      </div>
                    )}
                    <span>{f({ id: 'reduce' })}</span>
                    <img src={ic_decrease} alt="" height={24} width={24} style={{ margin: '0 0 4px 4px' }} />
                  </button>
                  <div className="amountProfit">
                    <p className="amountProfitDescription">
                      {f({ id: 'Profit' })} (VNĐ) <span>{handleCheckWinRate(dataGameInfo, select, 'percent')}</span>
                    </p>
                    <p className="amountProfitAmount">
                      +{' '}
                      {currencyFormat(
                        Math.abs(
                          chipIncrease * handleCheckWinRate(dataGameInfo, true, 'amount') -
                            chipReduce * handleCheckWinRate(dataGameInfo, false, 'amount')
                        ) || 0
                      )}
                    </p>
                  </div>
                  <div className="lineRed">
                    <div
                      className="lineGreen"
                      style={{
                        width: `${totalIncrease || totalReduce ? (totalIncrease * 100) / (totalIncrease + totalReduce) : 50}%`
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="textRed d-flex align-items-center">
                      <img alt="" src={icCoin} width={12} className={'cursor-pointer'} height={12} style={{ marginRight: '2px' }} />
                      {formatToPrice(totalReduce)}
                    </p>
                    <p className="textGreen d-flex align-items-center">
                      <img alt="" src={icCoin} width={12} className={'cursor-pointer'} height={12} style={{ marginRight: '2px' }} />
                      {formatToPrice(totalIncrease)}
                    </p>
                  </div>
                </div>
                <div className="chartContainerRightTop">
                  <div className={`d-flex justify-content-between align-items-center ${(!user?.token || !bookTime) && 'isDisable'}`}>
                    {dataChip
                      .filter((item) => item.show)
                      .map((item, index) => {
                        return (
                          <div className="position-relative" style={{ width: '19.2%', cursor: 'pointer' }} id={item.id} key={index}>
                            <img
                              alt=""
                              src={item.icon}
                              style={{ width: '100%', cursor: 'pointer', boxShadow: '0px 0px 10px 0px rgba(94,94,94,1)', borderRadius: '50%' }}
                              onClick={(e) => {
                                handleAddChip(item.value)
                                handleAnimationDesktop(e, item.id, item.value)
                              }}
                            />
                          </div>
                        )
                      })}
                    <div style={{ height: '24px', width: '1px', background: '#24345A' }} />
                    <div
                      className="position-relative"
                      style={{ width: '19.2%', cursor: 'pointer', boxShadow: '0px 0px 10px 0px rgba(94,94,94,1)', borderRadius: '50%' }}>
                      <img alt="" src={icChipAdd} style={{ width: '100%', cursor: 'pointer' }} onClick={() => setShowModalSelectChip(true)} />
                    </div>
                  </div>
                </div>
                <div className="chartContainerRightBot">
                  <div className="d-flex justify-content-between">
                    <button
                      className={`${(!user?.token || !bookTime) && 'isDisableGrey'} btnCancel`}
                      onClick={() => {
                        setChipIncrease(0)
                        setChipReduce(0)
                      }}>
                      <img src={ic_close} alt="" style={{ marginRight: '2px' }} />
                      {f({ id: 'cancel' })}
                    </button>
                    <button className={`btnCancel ${(!user?.token || !bookTime) && 'isDisableGrey'}`} onClick={() => handleRepeat()}>
                      <img src={ic_repeat} alt="" style={{ marginRight: '2px' }} />
                      {f({ id: 'repeat' })}
                    </button>
                  </div>
                  <button
                    className={`btnSelect ${(!user?.token || !bookTime) && 'isDisableGrey'}`}
                    onClick={() => {
                      handleSetCommand()
                      setCheckPlayRecordThisTurn(true)
                    }}
                    disabled={!bookTime}>
                    {f({ id: 'confirm' })}
                  </button>
                </div>
              </div>
            </div>
            <div className="chartLayoutBottom">
              <div className="chartContainerLeftBot">
                <div className="chartContainerLeftBotHead">
                  <div className="chartContainerLeftBotHeadContent1 bgGreen2">
                    <span>{`${f({ id: 'increase' })} ${countResultGameBo(resultGameBO, 'TANG')}`}</span>
                  </div>
                  <div className="chartContainerLeftBotHeadContent2 bgRed2">
                    <span>{`${f({ id: 'reduce' })} ${countResultGameBo(resultGameBO, 'GIAM')}`}</span>
                  </div>
                </div>
                <div className="d-flex position-relative">
                  <div className="chartResultLeft">
                    <div className="chartResult">
                      {[...new Array(4)].map((item, index) => {
                        return (
                          <div className={`chartResultElement`} key={index}>
                            {resultGameBO?.slice(index * 36, index * 36 + 36)?.map((item, indexchild) => {
                              return (
                                <div
                                  key={`circle_${indexchild}`}
                                  className={`chartResultCircle ${
                                    item?.gameRecordResult
                                      ? item?.gameRecordResult === 'TANG'
                                        ? 'bgGreen2'
                                        : item?.gameRecordResult === 'GIAM'
                                        ? 'bgRed2'
                                        : item?.gameRecordResult === 'HOA'
                                        ? 'bgYellow'
                                        : 'bgBlack'
                                      : 'bgBlack'
                                  }`}
                                />
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="chartResultRight">
                    <div className="chartResultV2">
                      {resultGameBOV2?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`chartResultCircle ${
                              item?.gameRecordResult
                                ? item?.gameRecordResult === 'TANG'
                                  ? 'borderGreen2'
                                  : item?.gameRecordResult === 'GIAM'
                                  ? 'borderRed2'
                                  : item?.gameRecordResult === 'HOA'
                                  ? 'borderYellow'
                                  : 'borderGray'
                                : 'borderGray'
                            }`}
                          />
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutAccount>
      {showModalSelectChip && (
        <ModalSelectChip
          showModalSelectChip={showModalSelectChip}
          closeModal={() => setShowModalSelectChip(false)}
          dataChip={dataChip}
          setDataChip={setDataChip}
        />
      )}
      {showModalBetLose && (
        <ModalShowBetLose
          // video={video_betlose}
          title={`${f({ id: 'Period' })} ${periodCode}`}
          content={f({ id: 'WISH_YOU_LUCK_NEXT_TIME' })}
          showModalResult={showModalBetLose}
          closeModal={() => setShowModalBetLose(false)}
          setShowHistory={setShowHistory}
        />
      )}
      {showModalBetWin && (
        <ModalShowBetWin
          // video={video_betwin}
          title={f({ id: 'Congratulations' })}
          content={`+ ${moneyWinLose} VNĐ`}
          showModalResult={showModalBetWin}
          closeModal={() => setShowModalBetWin(false)}
          setShowHistory={setShowHistory}
        />
      )}
      {showModalResult && (
        <ModalShowResult
          // video={video_betsuccess}
          title={`${f({ id: 'Period' })} ${periodCode}`}
          content={f({ id: 'ORDER_SUCCESSFUL' })}
          showModalResult={showModalResult}
          closeModal={() => setShowModalResult(false)}
          setShowHistory={setShowHistory}
        />
      )}
      {/* {isOpenModalConfirmCommonUse && <ModalConfirmCommonUse setIsOpenModal={setIsOpenModalConfirmCommonUse} content={dataContentModal} />} */}
      {/* {
        loading && <Loading />
      } */}
    </>
  )
}
export default injectIntl(DetailChartV2)
