export const AUDIO_LIST = [
  {
    index: 0,
    name: 'Những Lời Hứa Bỏ Quên - Vũ _ Okeda.net'
  },
  {
    index: 1,
    name: 'Gặp Em Lúc Tan Vỡ - Trung Quân _ Okeda.net'
  },
  {
    index: 2,
    name: 'Qua Cầu Rước Em - Hana Cẩm Tiên _ Okeda.net'
  },
  {
    index: 3,
    name: 'Khóc Ở Trong Club - Hiền Hồ _ Okeda.net'
  },
  {
    index: 4,
    name: 'Everytime We Touch - Shania Yan Cover _ Okeda.net'
  },
  {
    index: 5,
    name: 'Có Ai Hẹn Hò Cùng Em Chưa - Quân A.P _ Okeda.net'
  },
  {
    index: 6,
    name: 'Thứ Anh Chờ Không Phải Tuyết - Vũ Thịnh Cover _ Okeda.net'
  },
  {
    index: 7,
    name: 'Cô Đơn Đã Quá Bình Thường - Miu Lê _ Okeda.net'
  },
  {
    index: 8,
    name: 'Em Xinh - Mono _Okeda.net'
  },
  {
    index: 9,
    name: 'Waiting For You - Mono _ Okeda.net'
  },
  {
    index: 10,
    name: 'Ngày Tết Quê Em - Trung Đức (Remix) _ Okeda.net'
  }
]
